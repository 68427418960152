import { createApp } from 'vue';
import VueLazyLoad from 'vue3-lazyload'
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import Toaster from "@meforma/vue-toaster";

const feather = require('feather-icons');
feather.replace();

createApp(App)
	.use(router)
	.use(BackToTop)
	.use(Toaster)
	.use(VueLazyLoad)
	.mount('#app');

